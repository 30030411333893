<template>
  <div>
    <div class="djyl">
      <div class="h1">创先争优作表率    党旗引领新发展</div>
      <div class="h2">打造党建主阵地</div>
    </div>
    <div class="w1200 ct">
      <div class="title">习近平主席在论坛开幕式上的主旨演讲凝聚强大合力</div>
      <div class="flex">
        <div class="left1">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="item in 4" :key="item">
              <h3>{{ item }}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="left2 flex-1">
            <div class="tzgg"><div class="line"></div>通知公告<span class="more" @click="goto">查看更多</span></div>
        </div>
      </div>
      <div class="title1 flex"><span class="flex-1"></span>党建引领<span class="flex-1"></span></div>
      <div class="content">
        黄骅市在党建引领企业发展方面采取了一系列创新的举措，打造了“党建+企业服务”模式，通过全周期的工作机制，为企业提供全方位的服务和支持。这些举措有效地将党建工作融入企业管理和服务中，帮助企业解决实际问题，推动政策落地和企业发展，促进了企业的健康稳定发展。同时，这些创新举措也为企业带来了实际的好处，如提高了企业的竞争力，增强了企业的社会责任感和公信力，提升了企业的品牌形象和知名度等。
      </div>
      <div>
        <el-carousel :interval="4000" type="card" :autoplay="false" indicator-position="none" height="200px">
          <el-carousel-item v-for="item in 6" :key="item">
            <h3 class="medium">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="title1 flex"><span class="flex-1"></span>特色党建<span class="flex-1"></span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  methods:{
    goto(){
      this.$router.push({
        path:'/zjdj'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.w1200{
  width: 1200px;
  margin: 0 auto;
}
.title1{
  width: 1200px;
  margin:30px auto;
  position: relative;
  font-size: 20px;
  font-weight:550;
  color:#F90D0D ;
  span{
    border-top: 2px solid #F90D0D;
    margin: 0 15px;
    margin-top: 14px;
  }
}
.content{
  font-size: 14px;
  color: #4C4C4C;
  line-height: 25px;
  padding: 20px 30px;
}
.djyl{
  background: url("../../assets/djyl/djylbg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 160px;
  color: #fff;
  .h1{
    text-align: center;
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    text-align: center;
    padding: 15px 0;
    padding-bottom: 70px;
    font-size: 22px;
  }
}
.ct{
  margin-top: 50px;
  .title{
    font-size: 22px;
    text-align: center;
    padding-bottom: 45px;
  }
  .left1{
    width: 680px;
    height: 340px;
    margin-right: 36px;
  }
  .left2{
    position: relative;
    .line{
      position: absolute;
      left: 0;
      top: 0;
      border-left: 8px solid #F90D0D;
      height: 25px;
    }
    .tzgg{
      border-bottom: 1px solid #F90D0D;
      color: #3B3E45;
      font-size: 18px;
      font-weight: 550;
      padding-bottom: 8px;
      padding-left: 18px;
    }
    .more{
      color: #3B3E45;
      font-size: 14px;
      float: right;
      font-weight: 500;
    }
  }
}
</style>